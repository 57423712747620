import { datadogRum } from '@datadog/browser-rum';
import { REACT_APP_ENVIRONMENT, DD_APPLICATION_ID, DD_CLIENT_TOKEN } from './env-vars';

export const initializeDatadogRum = () => {
  datadogRum.init({
    applicationId: DD_APPLICATION_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'flyte-health-web-app',
    env: REACT_APP_ENVIRONMENT || 'development',
    version: '24.12.11',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};
