export const API_ROOT = process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : process.env.REACT_APP_API_ROOT;
export const SOCKET_ROOT = process.env.NODE_ENV !== 'development' ? `${window.location.origin}/` : process.env.REACT_APP_SOCKET_ROOT;
export const RP_CLIENT_URL = process.env.REACT_APP_RP_CLIENT_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

export const DEFAULT_PAGINATION_LIMIT = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT;

export const ICAL_DOMAIN = process.env.REACT_APP_ICAL_DOMAIN;
export const ICAL_COMPANY = process.env.REACT_APP_ICAL_COMPANY;
export const ICAL_PRODUCT = process.env.REACT_APP_ICAL_PRODUCT;
export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const PENDO_ENABLED = process.env.REACT_APP_PENDO_ENABLED?.toLowerCase();
export const LAUNCHDARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;

export const SCHEDULER_LICENSE_KEY = process.env.REACT_APP_SCHEDULER_LICENSE_KEY;
export const RECAPTCHA_SCRIPT_LOADING_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;

export const { REACT_APP_ENVIRONMENT } = process.env;
export const DD_APPLICATION_ID = process.env.REACT_APP_DD_APPLICATION_ID;
export const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;
